// Utility Classes

// SVG Support Styles
.iconset {
  position: absolute;
  height: 0;
  width: 0;
}

.icon {
  fill: currentColor;
  display: inline-block;
  width: 26px;
  height: 26px;
}

// sb hr
.sb-hr {
  color: #FFF;
  margin: 20px auto;
  height: 40px;
  width: 90%;
  max-width: 100%;
  opacity: 0.75;
  overflow: hidden;

  // add this class to fade out hr lines at the ends
  $end: rgba(255, 255, 255, 0);
  $middle: currentColor;

  &.fade {
    &::before {
      background: -moz-linear-gradient(left, $end 0%, $middle 25%, $middle 100%);

      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, $end 0%, $middle 25%, $middle 100%);

      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, $end 0%, $middle 25%, $middle 100%);

      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);

      /* IE6-9 */
    }

    &::after {
      background: -moz-linear-gradient(left, $middle 0%, $middle 75%, $end 100%);

      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, $middle 0%, $middle 75%, $end 100%);

      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, $middle 0%, $middle 75%, $end 100%);

      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);

      /* IE6-9 */
    }
  }

  // hr lines
  // before: left
  // after: right
  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: currentColor;
    width: calc(50% - 35px);
    height: 1px;
    top: 50%;
  }

  // svg
  .icon-sb-mark {
    width: 100%;
    height: 100%;
  }
}

.sbf-fill-brand-blue {
  fill: $sbf-palette-blue-500 !important;
}

.sbf-fill-brand-green {
  fill: $sbf-palette-green-600 !important;
}

.sbf-fill-white {
  fill: $sbf-palette-white !important;
}

.sbf-box-shadow {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1) !important;
}

// borders

.sbf-border {
  border: 1px solid $sbf-palette-gray-300 !important;
}

.sbf-border--top {
  border-top: 1px solid $sbf-palette-gray-300 !important;
}

.sbf-border--right {
  border-right: 1px solid $sbf-palette-gray-300 !important;
}

.sbf-border--bottom {
  border-bottom: 1px solid $sbf-palette-gray-300 !important;
}

.sbf-border--left {
  border-left: 1px solid $sbf-palette-gray-300 !important;
}

.sbf-border-0 {
  border: 0 !important;
}

.sbf-border--top-0 {
  border-top: 0 !important;
}

.sbf-border--right-0 {
  border-right: 0 !important;
}

.sbf-border--bottom-0 {
  border-bottom: 0 !important;
}

.sbf-border--left-0 {
  border-left: 0 !important;
}

.sbf-border-radius-3 {
  border-radius: 3px !important;
}

.sbf-border--top-brand-green {
  border-top: 5px solid $sbf-palette-green-600 !important;
}

.sbf-border--bottom-brand-green {
  border-bottom: 5px solid $sbf-palette-green-600 !important;
}

.sbf-border--top-brand-blue {
  border-top: 5px solid $sbf-palette-blue-500 !important;
}

.sbf-border--bottom-brand-blue {
  border-bottom: 5px solid $sbf-palette-blue-500 !important;
}

.sbf-border--top-green {
  border-top: 5px solid $sbf-palette-green-500 !important;
}

.sbf-border--bottom-green {
  border-bottom: 5px solid $sbf-palette-green-500 !important;
}

.sbf-border--top-orange {
  border-top: 5px solid $sbf-palette-orange-400 !important;
}

.sbf-border--bottom-orange {
  border-bottom: 5px solid $sbf-palette-orange-400 !important;
}

.sbf-border--top-light-green {
  border-top: 5px solid $sbf-palette-green-400 !important;
}

.sbf-border--bottom-light-green {
  border-bottom: 5px solid $sbf-palette-green-400 !important;
}

.sbf-border--top-purple {
  border-top: 5px solid $sbf-palette-purple-300 !important;
}

.sbf-border--bottom-purple {
  border-bottom: 5px solid $sbf-palette-purple-300 !important;
}

.sbf-border--top-pink {
  border-top: 5px solid $sbf-palette-pink-300 !important;
}

.sbf-border--bottom-pink {
  border-bottom: 5px solid $sbf-palette-pink-300 !important;
}

.sbf-border--top-blue {
  border-top: 5px solid $sbf-palette-blue-400 !important;
}

.sbf-border--bottom-blue {
  border-bottom: 5px solid $sbf-palette-blue-400 !important;
}

.sbf-border--top-light-blue {
  border-top: 5px solid $sbf-palette-blue-300 !important;
}

.sbf-border--bottom-light-blue {
  border-bottom: 5px solid $sbf-palette-blue-300 !important;
}

// Content Alignment

.sbf-content-left {
  text-align: left !important;
}

.sbf-content-center {
  text-align: center !important;
}

.sbf-content-right {
  text-align: right !important;
}

.sbf-content-nowrap {
  white-space: nowrap !important;
}

.sbf-content-lowercase {
  text-transform: lowercase !important;
}

.sbf-content-uppercase {
  text-transform: uppercase !important;
}

.sbf-content-bold {
  font-weight: 700 !important;
}

.sbf-content-medium {
  font-weight: 500 !important;
}

.sbf-content-normal {
  font-weight: 400 !important;
}

.sbf-content-italic {
  font-style: italic !important;
}

.sbf-content-pink {
  color: $sbf-palette-pink-400 !important;
}

.sbf-content-blue {
  color: $sbf-palette-blue-500 !important;
}

.sbf-content-green {
  color: $sbf-palette-green-700 !important;
}

// Flexbox

.sbf-display-flex {
  display: flex !important;
}

.sbf-flex-row {
  flex-direction: row !important;
}

.sbf-flex-column {
  flex-direction: column !important;
}

.sbf-justify-content-start {
  justify-content: flex-start !important;
}

.sbf-justify-content-end {
  justify-content: flex-end !important;
}

.sbf-justify-content-center {
  justify-content: center !important;
}

.sbf-align-items-start {
  align-items: flex-start !important;
}

.sbf-align-items-end {
  align-items: flex-end !important;
}

.sbf-align-items-center {
  align-items: center !important;
}

.sbf-align-items-stretch {
  align-items: stretch !important;
}

.sbf-align-self-start {
  align-self: flex-start !important;
}

.sbf-align-self-end {
  align-self: flex-end !important;
}

.sbf-align-self-center {
  align-self: center !important;
}

.sbf-align-self-stretch {
  align-self: stretch !important;
}
